@import './index';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: $my-bcg;
  min-height: 100vh;
}

h1 {
  font-family: 'Space Mono', monospace;
  font-size: 44px;
  margin: 0 0 12px 0;
  @include breakpoint($tablet) {
    font-size: 58px;
  }
  span {
    margin-right: 12px;
  }
}
h2 {
  font-family: 'Space Mono', monospace;
}
h3 {
  font-family: 'Space Mono', monospace;
}
h4 {
  font-family: 'Space Mono', monospace;
}
h5 {
  font-family: 'Space Mono', monospace;
}
h6 {
  font-family: 'Space Mono', monospace;
}
p {
  font-family: 'Roboto', sans-serif;
  margin: 0 0 6px 0;
  line-height: 1.5;
  &.hp-body {
    font-size: 22px;
    font-weight: 500;
    @include breakpoint($tablet) {
        font-size: 24px;
    }
  }
  &.hp-subtitle {
    font-size: 46px;
    font-weight: 700;
  }
  &.regular {
    font-weight: 400;
    text-decoration: none;
  }
  &.light {
    font-weight: 300;
    text-decoration: none;
  }
  &.subtitle {
    font-size: 24px;
    letter-spacing: 1px;
    font-style: italic;
    font-weight: 300;
  }
}
a {
  color: inherit;
  text-decoration: none;
  position: relative;
  transition: color .1s ease-in-out;
  display: inline-block;
  z-index: 2;
  &.no-style {
    &::after {
      display: none;
    }
    &:hover {
      // color: inherit;
      transition: all .1s ease-in-out;
    }
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 1px;
    left: -2px;
    height: 2px;
    width: calc(100% + 4px);
    background-color: white;
    z-index: -1;
    transition: all .1s ease-in-out;
  }
  &:hover {
    color: $my-red;
    transition: all .1s ease-in-out;
    &::after {
      height: calc(100% - 2px);
      transition: all .1s ease-in-out;
    }
  }
}
button, .btn {
  padding: 6px 16px;
  font-weight: 700;
  font-size: 18px;
  border: none;
  background-color: #ffffff;
  color: $my-red;
  cursor: pointer;
  transition: all .1s ease-in-out;
  &.inverted {
    color: #ffffff;
    background-color: $my-red;
    &:hover {
      transition: all .1s ease-in-out;
      color: #f1f1f1;
      background-color: $my-red;
    }
  }
  &:hover {
    transition: all .1s ease-in-out;
    background-color: #f1f1f1;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  
}
.container {
  max-width: 1200px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  &.spacing {
    margin: 120px auto;
  }
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  h2 {
    align-self: center;
    font-family: monospace;
  }
}
