@import '../../index.scss';

.visual-container-wrapper {
  width: 100%;
  height: 100%;
  font-size: 0;
  position: relative;

  .visual-controls-container {
    position: absolute;
    width: 100%;
    height: 100%;

    &.hide {
      display: none;
    }

    .visual-controls {
      position: absolute;
      color: $my-frg;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(0,0,0,0.25);
      padding: 12px 24px;
      bottom: 0;
      left: 0;
      button {
        font-size: 32px;
        color: $my-frg;
        background: none;
      }
    }
  }
}

.dg.main {
  position: absolute;
  right: 10px;
  top: 0;
}