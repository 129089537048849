@import '../../index.scss';

.app-wrapper {
    display: flex;
    width: 100vw;
    height: 100vh;
    .sidebar-wrapper {
        background-color: $my-bcg;
        height: 100%;
        width: 20%;
        border-right: 1px solid rgba(255,255,255,0.5);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 40px 20px;
        .sidebar-content {
            height: 100%;
            padding: 20px 0;
            .sidebar-content-title {
                font-size: 20px;
            }
            .sidebar-content-item {
                padding: 4px 0;
                font: normal 16px/1.25;
                &.selected {
                    color: $my-red
                }
            }
        }
        .sidebar-footer {
            .buy-me-a-beer {
                margin-top: 12px;
                font-size: 16px;
                .buy-me-a-beer-title {
                    font-size: 13px;
                    opacity: 0.5;
                }
            }
        }
    }
    .main-wrapper {
        height: 100%;
        width: 80%;
    }
}

.logo {
    height: 28px;
    @extend %fade;
    &:hover {
        fill: $my-red;
        @extend %fade;
    }
    @include breakpoint($tablet) {
        height: 32px;
    }
}


.social-links {
    display: flex;
    gap: 24px;
    a {
        align-self: center;
        @extend %fade;
        &:hover {
            color: $my-red;
            @extend %fade;
        }
    }
    h2 {
        font-size: 24px;
        margin: 0;
    }
}

.btn-consent {
    margin: 15px;
}

.logo {
    fill: white;
}
