@import '../../index.scss';

.homepage-wrapper {
  padding: 60px ;
  height: 100%;
  .homepage-head {
    .homepage-title {
      font-size: 48px;
    }
    .homepage-subtitle {
      font-family: "Roboto";
      font-weight: 500;
      color: $my-red;
    }
  }
}