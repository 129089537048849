@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&family=Space+Mono:wght@400;700&display=swap');

$monitor: 1500px;
$desktop-large: 1200px;
$desktop: 992px;
$tablet: 768px;
$mobile: 576px;
$mobile-small: 375px;

$my-red: #f74f3f;
$my-blue: #3c3cfc;
$my-bcg: #111111;
$my-frg: #ffffff;

%fade {
    transition: all .2s ease-in-out;
}

@mixin breakpoint($size) {
    @if $size==$mobile {
        @media (min-width: $mobile) {
            @content;
        }
    }

    @if $size==$tablet {
        @media (min-width: $tablet) {
            @content;
        }
    }

    @if $size==$desktop {
        @media (min-width: $desktop) {
            @content;
        }
    }

    @if $size==$desktop-large {
        @media (min-width: $desktop-large) {
            @content;
        }
    }

    @if $size==$monitor {
        @media (min-width: $monitor) {
            @content;
        }
    }

    @if $size==$mobile {
        @media (min-width: $mobile) {
            @content;
        }
    }

    @if $size==$tablet {
        @media (min-width: $tablet) {
            @content;
        }
    }

    @if $size==$mobile-small {
        @media (min-width: $mobile-small) {
            @content;
        }
    }

    @if $size==$mobile-small {
        @media (min-width: $mobile-small) {
            @content;
        }
    }

    @if $size==$desktop {
        @media (min-width: $desktop) {
            @content;
        }
    }

    @if $size==$desktop-large {
        @media (min-width: $desktop-large) {
            @content;
        }
    }
}
